<template>
  <div class="refund-list">
    <en-table-layout :tableData="pageData.data" :loading="loading">
      <template #toolbar>
        <el-form-item label="导出时间" class="col-auto">
          <el-date-picker style="width: 230px" v-model="advancedForm.order_time_range" type="daterange" size="medium"
            :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="数据类型" class="col-auto">
          <el-select style="width: 120px;" size="medium" v-model="params.order_refund" placeholder="请选择" clearable>
            <el-option label="全部" value=""></el-option>
            <el-option label="订单" value="1"></el-option>
            <el-option label="维权订单" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="col-auto">
          <el-button @click="advancedSearchEvent" size="small" type="primary">
            搜索
          </el-button>
        </el-form-item>
        <div class="col"></div>
      </template>
      <template slot="table-columns">
        <!--创建时间-->
        <el-table-column label="导出时间">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate }}
          </template>
        </el-table-column>
        <!--类型-->
        <el-table-column prop="order_type" label="数据类型">
          <template slot-scope="scope">
            {{ scope.row.order_type === 0 ? '订单' : '维权订单' }}
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <div>
              <el-button type="primary" size="mini" @click="submitImport(scope.row)">
                下载导出文件
              </el-button>
            </div>
          </template>
        </el-table-column>
      </template>
      <!--分页-->
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="pageData.page_size" :total="pageData.data_total"></el-pagination>
    </en-table-layout>

  </div>
</template>

<script>
import { getTradeExport, orderExportInfo } from '@/api/order';
import { handleDownload } from '@/utils';
import { Foundation } from '@/../ui-utils';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  name: 'unionExportList',
  components: {
    EnTableLayout
  },
  data () {
    return {

      //售后状态
      importLoading: false,
      /** 列表loading状态 */
      loading: false,
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        order_refund: ''
      },
      /** 列表数据 */
      tableData: null,
      /** 列表分页数据 */
      pageData: { data: [] },
      /** 高级搜索数据 */
      advancedForm: {
        order_time_range: null
      },

    };
  },
  mounted () {
    this.GET_RefundOrder();
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_RefundOrder();
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_RefundOrder();
    },
    /** 高级搜索事件触发 */
    advancedSearchEvent () {
      delete this.params.start_time;
      delete this.params.end_time;

      if (
        this.advancedForm.order_time_range &&
        this.advancedForm.order_time_range.length
      ) {
        if (typeof this.advancedForm.order_time_range[0] === "string") {
          this.params.start_time =
            new Date(this.advancedForm.order_time_range[0]).getTime() / 1000;
          this.params.end_time =
            new Date(this.advancedForm.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.start_time =
            this.advancedForm.order_time_range[0].getTime() / 1000;
          this.params.end_time =
            this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
        }
      }
      this.params.page_no = 1;
      this.GET_RefundOrder();
    },
    /** 获取退款单列表数据 */
    GET_RefundOrder () {
      this.loading = true;
      const params = {
        ...this.params
      };
      getTradeExport(params).then((resp) => {
        this.loading = false;
        this.pageData = resp;
      });
    },

    //导出
    submitImport (row) {
      this.importLoading = true;
      let filterVals = [//订单
        "name",
        "supplier_purchase_price",
        "product_id",
        "sku_sn",
        "num",
        "ship_addr",
        "ship_name",
        "ship_mobile",
        "order_sn",
        "order_status_text",
        "logi_name",
        //"ship_status_text",
        "ship_no",
        "mobile",
        "remark",
        "order_remark"
      ];
      let tHeaders = [
        "商品名称以及规格",
        "商品单价",
        "产品ID",
        "商品编号",
        "数量",
        "详细地址",
        "姓名",
        "电话",
        "子订单号",
        "订单状态",
        "物流公司",
        //"物流状态",
        "物流单号",
        "用户账号",
        '用户备注信息',
        '备注信息',
      ];
      if (row.order_type === 1) {//维权订单
        filterVals = [
          'name',
          'refund_sn',
          'trade_sn',
          'mobile',
          'remark',
          'create_time',
          'refuse_type_text',
          'refund_status_text',
        ];
        tHeaders = [
          '商品名称',
          '售后单号',
          '订单编号',
          '用户账号',
          '用户备注信息',
          '申请时间',
          '类型',
          '状态',
        ];
      }
      let params = { id: row.id };
      orderExportInfo(params)
        .then((response) => {
          if (row.order_type === 0) {//  0订单 1维权订单
            response = response.map(item => {
              const {
                ship_province,
                ship_city,
                ship_county,
                ship_town,
                ship_addr,
                mobel,
                login_account,
              } = item;
              item.ship_addr = ship_province + ship_city + ship_county + ship_town + ship_addr;
              let mobile_account = '';
              mobel ? mobile_account = mobile_account + mobel : '';
              login_account ? mobile_account = mobile_account + '' + login_account : '';
              item.mobel = mobile_account;
              if (item.order_remark) {
                const date = this.formatDate(item.remark_update_date * 1000)
                item.order_remark = date + "\n" + item.order_remark
              }
              item.order_status_text = item.jd_cancel_status == 1 ? "取消中" : item.order_status_text;
              return item;
            })
          } else {
            response.map((item, index) => {
              item.create_time = Foundation.unixToDate(
                item.create_time,
                'yyyy-MM-dd hh:mm:ss'
              );
            });
          }
          handleDownload(response, tHeaders, filterVals, '订单数据');
          this.importLoading = false;
        })
        .catch((res) => {
          this.importLoading = false;
        });
    },
    formatDate (time) {
      let date = new Date(time);
      let YY = date.getFullYear() + '-';
      let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
      let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      let ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
      return YY + MM + DD + " " + hh + mm + ss;
    },
  },
};
</script>

<style lang="scss">
.refund-list {
  .el-tabs__header {
    margin-bottom: 0;
  }
}
</style>
